import { CacheProvider } from "@emotion/react";
import React, { Component } from "react";
import createCache from "@emotion/cache";
/* import rtl from "stylis-plugin-rtl"; */
// NB: A unique `key` is important for it to work!
/* const options = {
  rtl: { key: "css-ar", stylisPlugins: [rtl] },
  ltr: { key: "css-en" },
}; */
export function RtlProvider({ children }) {
  const dir = document.documentElement.dir == "ar" ? "rtl" : "ltr";
  const cache = createCache(options[dir]);
  return <br />;
}
