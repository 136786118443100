import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import axios from "axios";
import { NavLink } from "react-router-dom";
import TagModal from "components/modals/tagModal";

export function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, children, ...rest } = props;
  const [input, setInput] = useState("");
  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("white", "navy.800");
const [openModal, setOpenModal]=useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure();


  const handleInputChange = (e) => {

    const inputValue = e.target.value;
    setInput(inputValue);


  };

  const handleKeyDown = (e) => {

    if (e.keyCode === 13) { // Check if Enter key is pressed
      e.preventDefault(); // Prevent form submission or other default behavior
      e.stopPropagation(); 
      if (input.length > 0) { // Check if input has content
        setOpenModal(true); // Open the modal
        onOpen(); // Trigger onOpen if needed
      } else {
        setOpenModal(false); // Close the modal if input is empty
      }
    } else {
      setOpenModal(false); // Close the modal for any other key
    }

  }


  return (
    <InputGroup borderRadius="8px" w="400px" {...rest}       onKeyDown={(e)=>handleKeyDown(e)} >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover={{}}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
          ></IconButton>
        }
      />
      <Input
        variant="search"
        fontSize="m"
        bg={inputBg}
        placeholder="Find tag..."
        value={input}
        onChange={handleInputChange}
   
     
      />
     {/*  {showModal && <Modal tagInput={input} closeModal={closeModal} />} */}
    {openModal && <TagModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        tagInput={input}
      />}
    </InputGroup>
  );
}

const Modal = ({ tagInput, closeModal }) => {
  const [productPic, setProductPic] = useState();

  const [tag, setTag] = useState();
  const [scans, setTotalScans] = useState(0);
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const boxRef = useRef(null);
  const { colorMode } = useColorMode();

  const flattenObject = (object) => {
    const ipAddress = object.ipAddress;
    const location = object.location;
    const createdAt = object.createdAt;

    const result = [];

    for (let i = 0; i < ipAddress.length; i++) {
      const flattenedObject = {
        ipAddress: ipAddress[i],
        location: location[i],
        companyId: object.companyId,
        createdAt: Array.isArray(createdAt[i]) ? createdAt[i][0] : createdAt[i],
        id: object.id,
        tagId: object.tagId,
        productId: object.productId,
      };

      result.push(flattenedObject);
    }

    return result;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    const getTag = async () => {
      axios
        .get(`/tag?tag=${tagInput}`)
        .then(async (res) => {
          setTag(res.data);
          axios
            .get(
              `/scan?tag=${tagInput}&company=${res.data.tag.companyId}&getAllTagScans=true`
            )
            .then(async (res) => {
              var result = [];
              var data = flattenObject(res.data);
              result.push(data);
              setTotalScans([...new Set(result.flat())].length);
              setFound(true);
              setLoading(false);
            })
            .catch((e) => {
              setFound(false);
              setLoading(false);
            });
        })
        .catch((e) => {
          setFound(false);
          setLoading(false);
        });
      try {
        await axios
          .get(`/s3?key=troofmedia/${tag.product.pictures[0]}`)
          .then((res) => {
            setProductPic(res.data);
          });
      } catch {}
    };

    getTag();
  }, [tagInput]);

  return (
    <Box
      ref={boxRef}
      bg={colorMode === "dark" ? "navy.700" : "white"}
      borderRadius={"md"}
      style={{
        position: "absolute",
        top: "20%",
        left: "50%",
        transform: "translateX(-50%)",
        width: "400px",
        padding: "16px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        textAlign: "center",
        marginTop: "10%",
      }}
    >
      {found && (
        <NavLink to={"/admin/scans?tag=" + tagInput} onClick={closeModal}>
          <Box borderWidth="0px" borderRadius="md" padding={0}>
            <Flex alignItems="center">
              <Image
                src={`data:image/jpeg;base64,${productPic}`}
                boxSize="70px"
                objectFit="cover"
                borderRadius="md"
                mr={4}
              />
              <Flex flexDirection="column" alignItems={"start"}>
                <Text fontWeight="bold">
                  {" "}
                  {tag?.product?.description?.name}
                </Text>
                <Text> Scanned: {scans} </Text>
                <Text> Distributor: {tag.product.distributorId} </Text>
               {/*  <Text fontSize="xs" color="gray.500">
                  Created: {new Date(tag.createdAt).toLocaleString()}
                </Text> */}
              </Flex>
            </Flex>
          </Box>
        </NavLink>
      )}
      {!found && !loading && <>No match</>}
      {loading && <>Looking for the tag...</>}
    </Box>
  );
};
