import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import { orderStatus } from "variables/charts";

function TablesOrdersRow(props) {
  const {
    product,
    orderID,
    status,
    quantity,
    progression,
    submission,
    due,
    isLast,
    orderNumber,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const statusMapping = ["#3182CE", "orange", "yellow", "green", "red"];

  return (
    <Tr>
      <Td
        pl="0px"
        borderBottom={isLast ? "none" : null}
        borderColor={borderColor}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {orderNumber}
        </Text>
      </Td>
      <Td
        pl="0px"
        borderBottom={isLast ? "none" : null}
        borderColor={borderColor}
      >
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          whiteSpace={"nowrap"}
        >
          {orderID}
        </Text>
      </Td>
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          whiteSpace={"nowrap"}
        >
          {product}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {quantity}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Badge
          bg={statusMapping[status]}
          fontSize="16px"
          p="4px 10px"
          borderRadius="8px"
          color={"white"}
        >
          {orderStatus[status]}
        </Badge>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {new Date(submission).toLocaleDateString()}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {new Date(due).toLocaleDateString()}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Flex direction="column">
          <Text
            fontSize="md"
            color={progression <= 2 ? "red" : "blue.500"}
            fontWeight="bold"
            pb=".2rem"
          >{`${progression} days`}</Text>
          <Progress
            colorScheme={progression <= 2 ? "red" : "blue"}
            size="xs"
            value={
              ((new Date().getTime() - new Date(submission).getTime()) /
                (24 * 60 * 60 * 1000) /
                ((new Date(due).getTime() - new Date(submission)) /
                  (24 * 60 * 60 * 1000))) *
              100
            }
            borderRadius="15px"
          />
        </Flex>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Button p="0px" bg="transparent" variant="no-effects">
          <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
        </Button>
      </Td>
    </Tr>
  );
}

export default TablesOrdersRow;
