import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  useColorMode,
  Input,
  Select,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { DownloadIcon } from "@chakra-ui/icons";
import useAuth from "views/Pages/useAuth";
import { parseMultilingualText } from "utils";

export default function EditProductModal(props) {
  const { isOpen, onClose, productObject } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [name, setName] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [longDescription, setLongDescription] = useState();
  const { colorMode } = useColorMode();
  const [pics, setPics] = useState([]);

  const [files, setFiles] = useState([]);
  const [refProduct, setRefProduct] = useState();
  const [genCode, setGenCode] = useState();
  const [brand, setBrand] = useState();
  const [category, setCategory] = useState();

  const [weightProduct, setWeightProduct] = useState();
  const [dimProduct, setDimProduct] = useState();
  const [comments, setComments] = useState();
  const [release, setRelease] = useState();
  const [isLoading, setLoading] = useState(false);

  const { company } = useAuth();

  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: "200px",
    padding: 4,
    boxSizing: "border-box",
    diplay: "flex",
    borderRadius: "15px",
    borderColor: "gray",
    border: "dotted",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
    padding: "5px",
    borderRadius: "15px",
  };

  const imageContainer = {
    padding: "10px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignContent: "space-between",
    borderRadius: "15px",
    borderColor: "gray",
  };

  const categories = ["Parfum", "Other"];

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // axios.put(
    //   `/s3?key=troofmedia/${acceptedFiles[0].name}`,
    //   acceptedFiles[0],

    //   {
    //     headers: {
    //       "Content-Type": acceptedFiles[0].type,
    //     },
    //   }
    // );
  }, []);

  const setImages = async () => {
    let temp = [];
    if (productObject.pictures) {
      for (var i = 0; i < productObject.pictures.length; i++) {
        if (productObject.pictures[i]) {
          await axios
            .get(`/s3?key=troofmedia/${productObject.pictures[i]}`)
            .then((res) => {
              temp.push(res.data);
            });
        }
      }
    }
    setPics(temp);
  };

  useEffect(() => {
    if (productObject && isOpen) {
      setRefProduct(productObject.productId);
      setGenCode(productObject.gencode);
      setBrand(parseMultilingualText(productObject.brand));
      setCategory(productObject.category);
      setName(parseMultilingualText(productObject.name));
      setShortDescription(
        parseMultilingualText(productObject.description?.short)
      );
      setLongDescription(
        parseMultilingualText(productObject.description?.long)
      );
      setWeightProduct(productObject.weight);
      if (productObject.dimensions) {
        setDimProduct(Object.values(productObject.dimensions).join("-"));
      }
      setComments(productObject.extra);
      setRelease(productObject.release);
      setImages();
    }
  }, [isOpen]);

  const getDimensions = () => {
    if (dimProduct) {
      if (!dimProduct.length) return null;

      const dims = dimProduct.split("-");

      return {
        length: dims[0],
        width: dims[1],
        height: dims[2],
      };
    }
  };

  const updateProduct = () => {
    setLoading(true);
    try {
      const pictures = pics;

      //push files to s3 bucket
      for (var i = 0; i < files.length; i++) {
        axios
          .put(
            `/s3?key=troofmedia/${company}_${refProduct}_${i + 1}`,
            files[i],

            {
              headers: {
                "Content-Type": files[i].type,
              },
            }
          )
          .then((res) => {})
          .catch((e) => {});

        pictures.push(`${company}_${refProduct}_${i + 1}`);
      }

      axios
        .put(`/product?productId=${refProduct}`, {
          productId: refProduct,
          pictures: pictures,
          companyId: company,
          name: name,
          description: {
            long: longDescription,
            short: shortDescription,
          },
          dimensions: getDimensions(),
          weight: weightProduct,
          brand: brand,
          category: category,
          release: release,
          gencode: genCode,
          extra: comments,
        })
        .then((res) => {
          onClose({
            name: name,
            description: shortDescription,
            category: category,
          });
          setLoading(false);
          showToast({
            status: "success",
            title: "Product updated successfully",
            description: "Your Product has been updated successfully",
          });
        })
        .catch((e) => {
          setLoading(false);
          showToast({
            status: "error",
            title: "Server error",
            description: "Please try again later",
          });
        });
    } catch (e) {
      showToast({
        status: "error",
        title: "Server error",
        description: "Something went wrong",
      });
    }
    setLoading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
    },
  });

  const thumbs = files.map((file) => (
    <div key={file.name} style={{ display: "flex" }}>
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} />
          <text
            key={file.path}
            style={{ alignSelf: "center", padding: "10px" }}
          >
            {file.path}
          </text>
        </div>
      </div>
      <button
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          borderRadius: "8px",
          padding: "4px 10px",
          backgroundColor: "#B92E34",
        }}
        onClick={() => {
          const newFiles = files.filter((f) => f.name !== file.name);
          setFiles(newFiles);
        }}
      >
        Remove
      </button>
    </div>
  ));

  const filepath = files.map((file) => <li key={file.path}>{file.path}</li>);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          {" "}
          <FormControl>
            <FormLabel>Product Name </FormLabel>
            <Input
              placeholder="Product name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product ID</FormLabel>
            <Input
              ref={initialRef}
              placeholder="Product reference"
              value={refProduct}
              disabled
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product Gencode </FormLabel>
            <Input placeholder="Product Gencode" value={genCode} disabled />
          </FormControl>
          <FormControl>
            <FormLabel>Brand Name </FormLabel>
            <Input
              placeholder="Brand name"
              onChange={(e) => setBrand(e.target.value)}
              value={brand}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product Category </FormLabel>
            <Select
              placeholder="Choose a category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Product short description </FormLabel>
            <Input
              placeholder="Product short description"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product long description </FormLabel>
            <textarea
              placeholder="Description..."
              style={{
                width: "100%",
                border: "solid",
                borderRadius: "5px",
                borderWidth: "0.5px",
                borderColor: "",
                padding: "10px",
                backgroundColor: "transparent",
              }}
              size="lg"
              type="text"
              rows={4}
              value={longDescription}
              onChange={(e) => setLongDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product weight </FormLabel>
            <Input
              placeholder="Product weight"
              onChange={(e) => setWeightProduct(e.target.value)}
              value={weightProduct}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Product dimensions (length-width-height in cm){" "}
            </FormLabel>
            <Input
              placeholder="23-20-10"
              onChange={(e) => setDimProduct(e.target.value)}
              value={dimProduct}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Year of 1st commercialization </FormLabel>
            <Input
              placeholder="Year of 1st commercialization"
              onChange={(e) => setRelease(e.target.value)}
              value={release}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Extra comments </FormLabel>
            <textarea
              style={{
                width: "100%",
                border: "solid",
                borderRadius: "5px",
                borderWidth: "0.5px",
                borderColor: "",
                padding: "10px",
                backgroundColor: "transparent",
              }}
              size="lg"
              type="text"
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </FormControl>
          <div
            {...getRootProps()}
            style={{
              borderRadius: "10px",
              padding: "10px",
              height: "100px",
              borderColor: "gray",
              alignItems: "center",
              justifyItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "transparent",
              border: "dotted",
              marginTop: "15px",
              color: colorMode,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                <DownloadIcon w={10} h={10} />
                <p>
                  Drag 'n' drop your product pictures here, or click to select
                  files
                </p>
              </>
            )}
          </div>
          {thumbs.length > 0 && (
            <div>
              <aside>
                <h3 style={{ fontSize: "20px", margin: "10px" }}>
                  Product Pictures
                </h3>
              </aside>

              <div style={imageContainer}>{thumbs}</div>
            </div>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {pics.map((item, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"data:image/jpeg;base64," + item}
                    style={{ width: "200px", borderRadius: "4px" }}
                  />
                  <Button>Remove</Button>
                </div>
              );
            })}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="blue"
            mr={3}
            ml={3}
            onClick={() => updateProduct()}
          >
            {isLoading ? <Spinner /> : "Update"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
