import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "views/Pages/useAuth";

export function ProtectedRoute({ loggedInPath, children, ...rest }) {
  const { user } = useAuth();

  useEffect(() => {}, [user]);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (window.localStorage.getItem("troof_token")) {
          return children;
        }

        if (!user) {
          return <Redirect to={"/auth/signin"} />;
        }

        return null;
      }}
    />
  );
}
