/*!

=========================================================
* Argon Dashboard Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-chakra/blob/master/LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js"; // Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
import { ProtectedRoute } from "helpers/protected";
import { AuthProvider } from "../src/views/Pages/useAuth";

export function App() {
  return (
    <ChakraProvider theme={theme} resetCss={false} position="relative">
      <HashRouter>
        <AuthProvider>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <ProtectedRoute path={`/admin`}>
              <AdminLayout />
            </ProtectedRoute>
            <Route path={`/rtl`} component={RTLLayout} />
            <Redirect from={"/"} to={"auth/signin"} />
          </Switch>
        </AuthProvider>
      </HashRouter>
    </ChakraProvider>
  );
}
export default App;
