import {
  Table,
  Tbody,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Tr,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  Box,
  Td,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";

export default function ConfirmModal(props) {
  const { isOpen, onClose, confirmDelete } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete this item?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            bg="red.500"
            color="white"
            mr={3}
            ml={3}
            onClick={() => confirmDelete()}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
