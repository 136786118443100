// Chakra imports
import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ProductsTableRow from "components/Tables/ProductsTable";
import NewProductModal from "components/modals/newProductModal";
import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";

import axios from "axios";
import useAuth from "views/Pages/useAuth";
import NewBatchProductsModal from "components/modals/newBatchProducts";
import Pagination from "components/Tables/Pagination";
import LoadingIcon from "components/Icons/LoadingIcon";
import { parseMultilingualText } from "utils";

function Products() {
  const textColor = useColorModeValue("gsray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenBatch,
    onOpen: onOpenBatch,
    onClose: onCloseBatch,
  } = useDisclosure();
  const [products, setProducts] = useState([]);
  const [pics, setPics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPic, setIsLoadingPic] = useState(true);
  const { company } = useAuth();
  const [currentPage, setCurrentPage] = React.useState(1);
  const limit = 5;
  const [totalProducts, setTotalProducts] = useState(0);
  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchProducts(page);
  };

  const removeProduct = (id) => {
    let temp = products.filter((product) => product.productId !== id);
    setProducts(temp);
  };

  const addedProduct = () => {
    fetchProducts();
  };

  const fetchProducts = async function (page = 1) {
    setIsLoading(true);
    await axios
      .get(`/product?companyId=${company}&all=true`)
      .then((response) => {
        setTotalProducts(response.data.length);
        setProducts(response.data);
        return response.data;
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the products",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (company) {
      fetchProducts();
    }
  }, [company]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX="scroll" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Registered Products
          </Text>
        </CardHeader>
        <CardBody style={{ padding: "5px" }}>
          <Button onClick={() => fetchProducts()} style={{ margin: "5px" }}>
            <FiRefreshCw />
          </Button>
          <Button onClick={onOpen} style={{ margin: "5px" }}>
            Add Single Product
          </Button>
          <Button onClick={onOpenBatch} style={{ margin: "5px" }}>
            Add Multiple Products
          </Button>
          <NewProductModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            addedProduct={addedProduct}
          />
          <NewBatchProductsModal
            isOpen={isOpenBatch}
            onOpen={onOpenBatch}
            onClose={onCloseBatch}
          />
        </CardBody>
        <CardBody style={{ marginTop: "20px" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" borderColor={borderColor} color="gray.400">
                  Product
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  ID
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Category
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Added on
                </Th>
                <Th borderColor={borderColor}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                products.length > 0 &&
                products.map((row, index, arr) => {
                    console.log(row)
                  return (
                    <ProductsTableRow
                      key={row.productId}
                      name={parseMultilingualText(row.name)}
                      description={parseMultilingualText(
                        row.description?.short
                      )}
                      category={parseMultilingualText(row?.category)}
                      id={row.productId}
                      brand={parseMultilingualText(row.brand)}
                      logo={row.pictures.length ? row.pictures[0] : null}
                      date={row.createdAt}
                      isLast={index === arr.length - 1 ? true : false}
                      productObject={row}
                      removeProduct={removeProduct}
                      refetch={fetchProducts}
                    />
                  );
                })}
            </Tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                padding: "40px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                gap: "20px",
                display: "flex",
              }}
            >
              <LoadingIcon />
              <Text>Fetching products...</Text>
            </div>
          )}
          {products.length == 0 && !isLoading && totalProducts == 0 && (
            <div
              style={{
                padding: "15px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{ padding: "10px" }}>
                Looks like you have no products yet..
              </Text>
              <Button onClick={onOpen}>Add your first product now!</Button>
            </div>
          )}
          {/*    {products.length == 0 && currentPage != 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={
                products.length / limit == 1 ? 1 : products.length / limit + 1
              }
              onPageChange={handlePageChange}
            />
          )} */}
          {/*    {products.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalProducts / limit}
              onPageChange={handlePageChange}
            />
          )} */}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Products;
