import { FiRefreshCw } from "react-icons/fi";
import { motion } from "framer-motion";

export default function LoadingIcon() {
  return (
    <motion.div
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        ease: "linear",
      }}
    >
      <FiRefreshCw />
    </motion.div>
  );
}
