
export function parseMultilingualText(encodedText, language = 'en') {
  const startTagEn = '[:en]';
  const startTagFr = '[:fr]';
  const endTag = '[:]';

  if(!encodedText){
    return encodedText
  }
  let startIndex, endIndex;

  if (language === 'en') {
    startIndex = encodedText.indexOf(startTagEn);
  } else if (language === 'fr') {
    startIndex = encodedText.indexOf(startTagFr);
  } 
if (language != 'en' && language !='fr'){
    return null
}
  if (startIndex === -1) {
    return encodedText; // Language tag not found, handle accordingly
  }

  // Find the end of the current language segment
  if (language=='en'){
  endIndex = encodedText.indexOf(startTagFr, startIndex);
}else{
     endIndex = encodedText.indexOf(endTag, startIndex);
}
  if (endIndex === -1) {
    return encodedText; // End tag not found, handle accordingly
  }

  // Extract the text segment
  const segmentText = encodedText.substring(startIndex + startTagEn.length, endIndex);

  // Find the start of the next language segment
  const nextStartIndex = encodedText.indexOf(language === 'en' ? startTagFr : startTagEn, endIndex);

  if (nextStartIndex === -1) {
    return segmentText.trim(); // No more segments, return the trimmed text
  }

  // Return the trimmed text up to the start of the next segment
  return segmentText.trim();
}