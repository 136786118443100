import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { DownloadIcon } from "@chakra-ui/icons";
import useAuth from "views/Pages/useAuth";
import CSVUploader from "helpers/uploadcsv";

export default function NewBatchProductsModal(props) {
  const { isOpen, onClose } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [files, setFiles] = useState([]);
  const [refProduct, setRefProduct] = useState();
  const [name, setName] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [longDescription, setLongDescription] = useState();
  const [title, setTitle] = useState();
  const [category, setCategory] = useState();
  const [genCode, setGenCode] = useState();
  const [weightProduct, setWeightProduct] = useState();
  const [dimProduct, setDimProduct] = useState();
  const [brand, setBrand] = useState();
  const [release, setRelease] = useState();
  const { company } = useAuth();

  const toast = useToast();

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: "auto",
    height: 200,
    padding: 4,
    boxSizing: "border-box",
    diplay: "flex",
    borderRadius: "15px",
    borderColor: "gray",
    border: "dotted",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
    padding: "5px",
    borderRadius: "15px",
  };

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const onSubmit = function () {
    try {
      const pics = [];
      //push files to s3 bucket
      for (var i = 0; i < files.length; i++) {
        axios
          .put(
            `/s3?key=troofmedia/${company}_${refProduct}_${i + 1}`,
            files[i],
            {
              headers: {
                "Content-Type": files[i].type,
              },
            }
          )
          .then((res) => {})
          .catch((e) => {});

        pics.push(`${company}_${refProduct}_${i + 1}`);
      }

      axios
        .post("/product", {
          productId: refProduct,
          pictures: pics,
          companyId: company,
          description: {
            title: title,
            name: name,
            longDescription: longDescription,
            shortDescription: shortDescription,
          },
          dimensions: dimProduct,
          weight: weightProduct,
          brand: brand,
          category: category,
          release: release,
        })
        .then((res) => {
          onClose();
          showToast({
            status: "success",
            title: "Products added successfully",
            description: "Your products have been added successfully",
          });
        })
        .catch((e) => {
          showToast({
            status: "error",
            title: "Server error",
            description: "Please try again later",
          });
        });
    } catch (e) {
      showToast({
        status: "error",
        title: "Server error",
        description: "Something went wrong",
      });
    }
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));
  const filepath = files.map((file) => <li key={file.path}>{file.path}</li>);
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"4xl"}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Upload New Products</ModalHeader>
        <ModalCloseButton />
        <CSVUploader />

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="blue"
            mr={3}
            ml={3}
            onClick={() => onSubmit()}
            type="submit"
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
