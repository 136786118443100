// Chakra imports
import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  useDisclosure,
  useToast,
  InputGroup,
  InputLeftElement,
  IconButton,
  Input,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";

import axios from "axios";

import NewDistributorModal from "components/modals/newDistributorModal";
import Pagination from "components/Tables/Pagination";
import LoadingIcon from "components/Icons/LoadingIcon";
import useAuth from "views/Pages/useAuth";
import DistributorsTableRow from "components/Tables/DistributorsTable";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SearchIcon } from "@chakra-ui/icons";

function Distributors() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [totalDistributors, setTotalDistributors] = useState(0);
  const [distributors, setDistributors] = useState([]);
  const [allDistributors, setAllDistributors] = useState([]);
  const [logos, setLogos] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const limit = 5;

  const { company } = useAuth();

  const toast = useToast();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchDistributors(page);
  };

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

 /*  const handleKeyDown = (e) => {

    if (e.keyCode === 13) { // Check if Enter key is pressed
      e.preventDefault(); // Prevent form submission or other default behavior
      e.stopPropagation(); 
      if (search.length > 0) { // Check if input has content
 setSearch(search)
  
      }}

  } */

  const fetchDistributors = async function (page = 1) {
    setIsLoading(true);
    await axios
      .get(`/distributor?companyId=${company}`)
      .then((response) => {
        setTotalDistributors(response.data.length);
        setDistributors(response.data.slice((page - 1) * limit, page * limit));
setAllDistributors(response.data)
        return response.data;
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the distributors",
        });
      });

    setIsLoading(false);
  };

  useEffect(() => {
    if (company) fetchDistributors();
  }, [company]);



  useEffect(() => {
    //setTotalDistributors(response.data.length);
    //setDistributors(response.data.slice((page - 1) * limit, page * limit));
    if (search.length !=""){
    const lowerCaseSearch = search.toLowerCase(); // Convert search term to lowercase
   const dists= allDistributors.filter(x => 
        x.name.toLowerCase().includes(lowerCaseSearch) // Check if the name includes the search term
    );

    setDistributors(dists)
    setTotalDistributors(dists.length)
  }else{
    setTotalDistributors(allDistributors.length);
setDistributors(allDistributors.slice((currentPage - 1) * limit, currentPage * limit))
  }
  }, [search]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX="scroll" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Distributors
          </Text>
        </CardHeader>

        <CardBody>
          <Flex>
          <Button onClick={() => fetchDistributors()} style={{ marginRight: "5px" }}>
            <FiRefreshCw />
          </Button>
          <Button onClick={onOpen}>Add</Button>
          <InputGroup borderRadius="8px" w="400px"       /* onKeyDown={(e)=>handleKeyDown(e)} */ >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover={{}}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={"black"} w="15px" h="15px" />}
          ></IconButton>
        }
      />
      <Input
        variant="search"
        fontSize="m"
        //bg={inputBg}
        placeholder="Find distributor..."
        value={search}
        onChange={(e)=>setSearch(e.target.value)}
   
     
      />

    
    </InputGroup>
          <NewDistributorModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          />
          </Flex>
        </CardBody>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" borderColor={borderColor} color="gray.400">
                  Distributor
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  ID
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  whiteSpace={"nowrap"}
                >
                  Vat
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  whiteSpace={"nowrap"}
                >
                  Sector
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  whiteSpace={"nowrap"}
                >
                  Address
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  whiteSpace={"nowrap"}
                >
                  Email
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  whiteSpace={"nowrap"}
                >
                  Distribution Network
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  whiteSpace={"nowrap"}
                >
                  Added On
                </Th>
                <Th borderColor={borderColor} color="gray.400"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                distributors.length > 0 &&
                distributors.map((row, index, arr) => {
                  return (
                    <DistributorsTableRow
                      name={row.name}
                      description={row.description}
                      sector={row.sector}
                      id={row.distributorId}
                      email={row.email}
                      date={row.createdAt}
                      vat={row.vat}
                      address={row.address}
                      network={row.network}
                      key={index}
                      companyId={row.companyId}
                      isLast={index === arr.length - 1 ? true : false}
                    />
                  );
                })}
            </Tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                padding: "40px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                gap: "20px",
                display: "flex",
              }}
            >
              <LoadingIcon />
              <Text>Fetching distributors...</Text>
            </div>
          )}
          {distributors.length == 0 && currentPage != 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={
                distributors.length / limit == 1
                  ? 1
                  : distributors.length / limit + 1
              }
              onPageChange={handlePageChange}
            />
          )}
          {distributors.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalDistributors / limit + 1}
              onPageChange={handlePageChange}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Distributors;
