import {
  Table,
  Tbody,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Tr,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  Box,
  Td,
} from "@chakra-ui/react";
import React from "react";

export default function AnomalyModal(props) {
  const { isOpen, onClose, anomaly } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Country Anomaly</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} overflowX={{ sm: "scroll" }}>
          <Table
            variant="simple"
            color={textColor}
            style={{ marginTop: "10px" }}
          >
            <Tbody>
              {anomaly &&
                anomaly.country.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>
                        <Text style={{ margin: "2px" }}>
                          Scan number {index + 1} happened in:{" "}
                          <span style={{ fontWeight: "600" }}>
                            {item.country}
                          </span>{" "}
                          /{" "}
                          <span style={{ fontWeight: "600" }}>
                            {" "}
                            {anomaly.city[index].city}
                          </span>{" "}
                          on {anomaly.date[index]}
                        </Text>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
