import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Icon,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  Box,
} from "@chakra-ui/react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import React from "react";
import ConfirmModal from "components/modals/confirmModal";
import EditDistributorModal from "components/modals/editDistributorModal";
import useAuth from "views/Pages/useAuth";
import axios from "axios";
import { ChevronRightIcon } from "@chakra-ui/icons";

function DistributorsTableRow(props) {
  const {
    name,
    description,
    id,
    date,
    isLast,
    sector,
    vat,
    address,
    email,
    network,
    companyId,
  } = props;

  const DeleteDisclosure = useDisclosure();
  const EditDisclosure = useDisclosure();
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const { company } = useAuth();
  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const deleteDistributor = async () => {
    await axios
      .delete(`/distributor?distributorId=${id}&companyId=${company}`)
      .then(() => {
        showToast({
          status: "success",
          title: "Distributor deleted",
          description: "Distributor deleted successfully",
        });
      })
      .catch((error) => {
        showToast({
          status: "error",
          title: "Error",
          description: "Error deleting Distributor",
        });
      });
    DeleteDisclosure.onClose();
  };

  return (
    <Tr className="row">
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {description}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {id}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {vat}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {sector}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {address}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {email}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex flexWrap="wrap">
          {network?.map((country, index) => (
            <Box key={index} m={1}>
              <Badge
                fontSize="sm"
                colorScheme="teal"
                borderRadius={4}
                px={2}
                py={1}
                display="flex"
                alignItems="center"
              >
                {country}
              </Badge>
            </Box>
          ))}
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          pb=".5rem"
          whiteSpace={"nowrap"}
        >
          {new Date(date).toUTCString()}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex
          direction={{ sm: "column", md: "row" }}
          align="flex-start"
          p={{ md: "24px" }}
        >
          <Button
            p="0px"
            bg="transparent"
            variant="no-effects"
            mb={{ sm: "10px", md: "0px" }}
            me={{ md: "12px" }}
          >
            <Flex
              color="red.500"
              cursor="pointer"
              align="center"
              p="12px"
              onClick={DeleteDisclosure.onOpen}
            >
              <Icon as={FaTrashAlt} me="4px" />
            </Flex>
          </Button>
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex
              color={textColor}
              cursor="pointer"
              align="center"
              p="12px"
              onClick={EditDisclosure.onOpen}
            >
              <Icon as={FaPencilAlt} me="4px" />
            </Flex>
          </Button>
        </Flex>

        <ConfirmModal
          isOpen={DeleteDisclosure.isOpen}
          onOpen={DeleteDisclosure.onOpen}
          onClose={DeleteDisclosure.onClose}
          confirmDelete={deleteDistributor}
        />

        <EditDistributorModal
          isOpen={EditDisclosure.isOpen}
          onOpen={EditDisclosure.onOpen}
          onClose={EditDisclosure.onClose}
          distributorObject={props}
        />
      </Td>
    </Tr>
  );
}

export default DistributorsTableRow;
