import {
  Avatar,
  Badge,
  Button,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EyeIcon } from "components/Icons/Icons";
import React from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import ConfirmModal from "components/modals/confirmModal";
import axios from "axios";

function BlogsTableRow(props) {
  const { id, logo, title, link, author, date, isLast, removeBlog } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const DeleteDisclosure = useDisclosure();
  const EditDisclosure = useDisclosure();
  const { colorMode } = useColorMode();

  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const deleteBlog = async () => {
    await axios
      .delete(`/blogpost?postId=${id}`)
      .then(() => {
        removeBlog(id);
        showToast({
          status: "success",
          title: "Blog deleted",
          description: "Blog deleted successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        showToast({
          status: "error",
          title: "Error",
          description: "Error deleting Blog",
        });
      });
    DeleteDisclosure.onClose();
  };

  return (
    <Tr className="row">
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar
            src={logo}
            w="80px"
            h="80px"
            borderRadius="12px"
            me="18px"
            ml="10px"
          />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {title}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {link}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {author}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date}
        </Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Button
          p="0px"
          bg="transparent"
          variant="no-effects"
          mb={{ sm: "10px", md: "0px" }}
          me={{ md: "12px" }}
        >
          <Flex
            color="red.500"
            cursor="pointer"
            align="center"
            p="12px"
            onClick={DeleteDisclosure.onOpen}
          >
            <Icon as={FaTrashAlt} me="4px" />
          </Flex>
        </Button>
        {/* <Button p="0px" bg="transparent" variant="no-effects">
          <Flex
            color={textColor}
            cursor="pointer"
            align="center"
            p="12px"
            onClick={EditDisclosure.onOpen}
          >
            <Icon as={FaPencilAlt} me="4px" />
          </Flex>
        </Button> */}

        <ConfirmModal
          isOpen={DeleteDisclosure.isOpen}
          onOpen={DeleteDisclosure.onOpen}
          onClose={DeleteDisclosure.onClose}
          confirmDelete={deleteBlog}
        />

        {/* <EditProductModal
          isOpen={EditDisclosure.isOpen}
          onOpen={EditDisclosure.onOpen}
          onClose={EditDisclosure.onClose}
          productObject={productObject}
        /> */}
      </Td>
    </Tr>
  );
}

export default BlogsTableRow;
