import { Box, Button, ButtonGroup, Stack } from "@chakra-ui/react";

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const renderPageButtons = () => {
    const buttons = [];

    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          size="sm"
          onClick={() => handlePageChange(i)}
          colorScheme={currentPage === i ? "blue" : "gray"}
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  const createButtonRows = () => {
    const buttons = renderPageButtons();
    const rows = [];

    for (let i = 0; i < buttons.length; i += 20) {
      rows.push(
        <ButtonGroup key={i} spacing={2}>
          {buttons.slice(i, i + 20)}
        </ButtonGroup>
      );
    }

    return rows;
  };

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} pb={"24px"}>
      <Stack spacing={2}>
        {createButtonRows()}
      </Stack>
      <Stack direction="row" spacing={2} align="center" mt={4}>
        <Button
          size="sm"
          disabled={isFirstPage}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </Button>
        <Button
          size="sm"
          disabled={isLastPage}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
}
