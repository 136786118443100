import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { countriesCoordinates } from "variables/map";

const countriesValeus = countriesCoordinates.map((c) => {
  return { label: c.name, value: c.name };
});

const economicActivities = [
  { label: "Retailing", value: "Retailing" },
  { label: "Wholesale", value: "Wholesale" },
  { label: "E-commerce", value: "E-commerce" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Importing and Exporting", value: "Importing and Exporting" },
  { label: "Distribution and Logistics", value: "Distribution and Logistics" },
  { label: "Marketing and Advertising", value: "Marketing and Advertising" },
  { label: "Real Estate", value: "Real Estate" },
  {
    label: "Banking and Financial Services",
    value: "Banking and Financial Services",
  },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Transportation", value: "Transportation" },
  { label: "Professional Services", value: "Professional Services" },
  {
    label: "Information Technology (IT) Services",
    value: "Information Technology (IT) Services",
  },
  { label: "Entertainment and Media", value: "Entertainment and Media" },
  {
    label: "Healthcare and Pharmaceuticals",
    value: "Healthcare and Pharmaceuticals",
  },
  { label: "Food and Beverage", value: "Food and Beverage" },
  { label: "Automotive", value: "Automotive" },
  { label: "Agriculture and Farming", value: "Agriculture and Farming" },
  { label: "Construction and Building", value: "Construction and Building" },
  { label: "Energy and Utilities", value: "Energy and Utilities" },
];

export default function EditDistributorModal(props) {
  const { isOpen, onClose, distributorObject } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [email, setEmail] = useState();
  const [sector, setSector] = useState();
  const [vat, setVat] = useState();
  const [address, setAddress] = useState();
  const [network, setNetwork] = useState([]);
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  useEffect(() => {
    if (distributorObject) {
      setName(distributorObject.name);
      setDescription(distributorObject.description);
      setEmail(distributorObject.email);
      setSector({
        label: distributorObject.sector,
        value: distributorObject.sector,
      });
      setAddress(distributorObject.address);
      setNetwork(
        distributorObject.network?.map((country) => {
          return {
            label: country,
            value: country,
          };
        })
      );
      setVat(distributorObject.vat);
    }
  }, [distributorObject]);

  const updateDistributor = function () {
    try {
      axios
        .put(
          `/distributor?distributorId=${distributorObject.id}&companyId=${distributorObject.companyId}`,
          {
            distributorId: distributorObject.id,
            companyId: distributorObject.companyId,
            email: email,
            description: description,
            name: name,
            description: description,
            sector: sector.value,
            vat: parseInt(vat),
            network: network?.map((e) => e.value),
            address: address,
          }
        )
        .then((res) => {
          onClose();
          showToast({
            status: "success",
            title: "Distributor updated successfully",
            description: "Your Distributor have been updated successfully",
          });
        })
        .catch((e) => {
          showToast({
            status: "error",
            title: "Server error",
            description: "Please try again later",
          });
        });
    } catch (e) {
      console.log(e);
      showToast({
        status: "error",
        title: "Server error",
        description: "Something went wrong",
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: colorMode == "dark" ? "#1f2733" : "white",
      color: colorMode == "dark" ? "white" : "black",
    }),
    option: (provided) => ({
      ...provided,
      background: colorMode == "dark" ? "#1f2733" : "white",
      color: colorMode == "dark" ? "white" : "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: colorMode == "dark" ? "white" : "black",
    }),
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>New Distributor</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <FormControl isRequired>
            <FormLabel>Company Name</FormLabel>
            <Input
              ref={initialRef}
              placeholder="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Email </FormLabel>
            <Input
              placeholder="contact email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Sector</FormLabel>
            <Select
              styles={customStyles}
              options={economicActivities}
              value={sector}
              onChange={(e) => setSector(e)}
            >
              {economicActivities.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Distribution Network</FormLabel>
            <Select
              defaultValue={[]}
              isMulti
              styles={customStyles}
              options={countriesValeus}
              value={network}
              onChange={(e) => setNetwork(e)}
              classNamePrefix="select"
              closeMenuOnSelect
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Address</FormLabel>
            <Input
              placeholder="Set your distributor's address"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>VAT number </FormLabel>
            <Input
              placeholder="Vat number"
              value={vat}
              onChange={(e) => setVat(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Distributor description </FormLabel>
            <textarea
              placeholder="Notes on ditributor..."
              style={{
                width: "100%",
                border: "solid",
                borderRadius: "5px",
                borderWidth: "0.5px",
                borderColor: "",
                padding: "10px",
                backgroundColor: "transparent",
              }}
              size="lg"
              type="text"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="blue"
            mr={3}
            ml={3}
            onClick={() => updateDistributor()}
            type="submit"
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
