import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { DownloadIcon } from "@chakra-ui/icons";
import useAuth from "views/Pages/useAuth";
import CSVUploader from "helpers/uploadcsv";
export default function NewProductModal(props) {
  const { isOpen, onClose, addedProduct } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [files, setFiles] = useState([]);
  const [refProduct, setRefProduct] = useState();
  const [name, setName] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [longDescription, setLongDescription] = useState();
  const [category, setCategory] = useState();
  const [genCode, setGenCode] = useState();
  const [weightProduct, setWeightProduct] = useState();
  const [dimProduct, setDimProduct] = useState([]);
  const [brand, setBrand] = useState();
  const [comments, setComments] = useState();
    const [compositions, setCompositions] = useState();
  const [release, setRelease] = useState(2024);
  const { company } = useAuth();
  const { colorMode } = useColorMode();

  const toast = useToast();

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: "auto",
    height: 200,
    padding: 4,
    boxSizing: "border-box",
    diplay: "flex",
    borderRadius: "15px",
    borderColor: "gray",
    border: "dotted",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    position: "relative",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
    padding: "5px",
    borderRadius: "15px",
  };

  const imageContainer = {
    padding: "10px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "space-between",
    borderRadius: "15px",
    borderColor: "gray",
  };

  const categories = ["Parfum", "Other"];

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
    },
  });

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const getDimensions = () => {
    if (!dimProduct?.length) return null;

    const dims = dimProduct.split("-");

    return {
      length: dims[0],
      width: dims[1],
      height: dims[2],
    };
  };

  const onSubmit = function () {
    try {
      const pics = [];
      //push files to s3 bucket
      for (var i = 0; i < files.length; i++) {
        axios
          .put(
            `/s3?key=troofmedia/${company}_${refProduct}_${i + 1}`,
            files[i],

            {
              headers: {
                "Content-Type": files[i].type,
              },
            }
          )
          .then((res) => {})
          .catch((e) => {});

        pics.push(`${company}_${refProduct}_${i + 1}`);
      }

      axios
        .post("/product", {
          productId: refProduct,
          pictures: pics,
          companyId: company,
          name: name,
          description: {
            long: longDescription,
            short: shortDescription,
          },
          dimensions: getDimensions(),
          weight: weightProduct,
          brand: brand,
          category: category,
          release: release,
          gencode: genCode,
          extra: comments,
          compositions: compositions,
        })
        .then((res) => {
          onClose();
          clearForm();
          showToast({
            status: "success",
            title: "Product added successfully",
            description: "Your Product have been added successfully",
          });
          addedProduct();
        })
        .catch((e) => {
          showToast({
            status: "error",
            title: "Server error",
            description: "Please try again later",
          });
        });
    } catch (e) {
      showToast({
        status: "error",
        title: "Server error",
        description: "Something went wrong",
      });
    }
  };

  const thumbs = files.map((file) => (
    <div key={file.name} style={{ display: "flex" }}>
      <div style={thumb}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} />
        </div>
      </div>
      <button
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          borderRadius: "8px",
          padding: "4px 10px",
          backgroundColor: "#B92E34",
        }}
        onClick={() => {
          const newFiles = files.filter((f) => f.name !== file.name);
          setFiles(newFiles);
        }}
      >
        Remove
      </button>
    </div>
  ));

  const filepath = files.map((file) => <li key={file.path}>{file.path}</li>);

  const clearForm = () => {
    setRefProduct("");
    setName("");
    setShortDescription("");
    setLongDescription("");
    setCategory("");
    setGenCode("");
    setWeightProduct("");
    setDimProduct("");
    setBrand("");
    setComments("");
    setRelease(2024);
    setFiles([]);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Register New Product</ModalHeader>
        <ModalCloseButton />
        <CSVUploader />
        <ModalBody
          pb={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <FormControl>
            <FormLabel>Product ID:</FormLabel>
            <Input
              ref={initialRef}
              placeholder="Product reference"
              onChange={(e) => setRefProduct(e.target.value)}
              value={refProduct}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product Gencode </FormLabel>
            <Input
              placeholder="Product Gencode"
              onChange={(e) => setGenCode(e.target.value)}
              value={genCode}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Brand Name </FormLabel>
            <Input
              placeholder="Brand name"
              onChange={(e) => setBrand(e.target.value)}
              value={brand}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product Category </FormLabel>
            <Select
              placeholder="Choose a category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Product Name </FormLabel>
            <Input
              placeholder="Product name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product short description </FormLabel>
            <Input
              placeholder="Product short description"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product long description </FormLabel>
            <textarea
              placeholder="Product long description"
              style={{
                width: "100%",
                border: "solid",
                borderRadius: "5px",
                borderWidth: "0.5px",
                borderColor: "",
                padding: "10px",
                backgroundColor: "transparent",
              }}
              size="lg"
              type="text"
              rows={4}
              value={longDescription}
              onChange={(e) => setLongDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product compositions </FormLabel>
            <Input
              placeholder="Product compositions"
              value={compositions}
              onChange={(e) => setCompositions(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Product weight </FormLabel>
            <Input
              placeholder="Product weight"
              onChange={(e) => setWeightProduct(e.target.value)}
              value={weightProduct}
            />
          </FormControl>

          <FormControl>
            <FormLabel>
              Product dimensions (length-width-height in cm)
            </FormLabel>
            <Input
              placeholder="23-20-10"
              onChange={(e) => setDimProduct(e.target.value)}
              value={dimProduct}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Year of 1st commercialization </FormLabel>
            <Input
              type="number"
              placeholder="Year of 1st commercialization"
              onChange={(e) => setRelease(e.target.value)}
              value={release}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Extra comments </FormLabel>
            <textarea
              placeholder="Extra comments"
              style={{
                width: "100%",
                border: "solid",
                borderRadius: "5px",
                borderWidth: "0.5px",
                borderColor: "",
                padding: "10px",
                backgroundColor: "transparent",
              }}
              size="lg"
              type="text"
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </FormControl>
          <div
            {...getRootProps()}
            style={{
              borderRadius: "10px",
              padding: "20px",
              borderColor: "gray",
              alignItems: "center",
              justifyItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "transparent",
              border: "dotted",
              marginTop: "15px",
              color: colorMode,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the pictures here ...</p>
            ) : (
              <>
                <DownloadIcon w={6} h={6} marginRight={"4px"} />
                <p>
                  Drag 'n' drop your product pictures here, or click to select
                  pictures
                </p>
              </>
            )}
          </div>
          {thumbs.length > 0 && (
            <div>
              <aside>
                <h3 style={{ fontSize: "20px", margin: "10px" }}>
                  Product Pictures
                </h3>
              </aside>
              <div style={imageContainer}>{thumbs}</div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="blue"
            mr={3}
            ml={3}
            onClick={() => onSubmit()}
            type="submit"
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
