import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { EyeIcon } from "components/Icons/Icons";
import { parseMultilingualText } from "utils";

function ScansTable(props) {
  const {
    product,
    tag,
    id,
    location,
    date,
    isLast,
    flag,
    distributor,
    selectScans,
    ipAddress,
    distributorName,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {tag}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "50px" }}
        pl="0px"
        borderBottom={isLast ? "none" : null}
        borderColor={borderColor}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {parseMultilingualText(product?.name)}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {distributorName == undefined ? distributor : distributorName}
          </Text>
        </Flex>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {location?.length}
          </Text>
        </Flex>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Button
          style={{ margin: "4px" }}
          onClick={() => selectScans({ location, date, ipAddress })}
        >
          <EyeIcon color={textColor} w="22px" h="22px" />
        </Button>
      </Td>

      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Badge
          bg={
            flag == 0 || flag == undefined
              ? "#fe4f10"
              : flag == 2
              ? "gray"
              : "yellow"
          }
          color={status === "ok" ? "white" : "white"}
          padding={"5px"}
          height={"30px"}
          marginBottom={"5px"}
          width={"30px"}
          borderRadius="8px"
        ></Badge>
      </Td>
    </Tr>
  );
}

export default ScansTable;
