import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "views/Pages/useAuth";

export default function OrderModal(props) {
  const { isOpen, onClose } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [deadline, setDeadline] = useState();
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState();
  const [productId, setProductId] = useState();
  const [comment, setComment] = useState("no comment");
  const { company } = useAuth();

  const toast = useToast();

  const fetchProducts = async function () {
    await axios
      .get(`/product?companyId=${company}&all=true`)
      .then((response) => {
        setProducts(response.data);
        return response.data;
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetchProducts();
  }, [company]);

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const placeOrder = async () => {
    await axios
      .put("/order", {
        productId: productId,
        productName: products.filter((item) => item.productId == productId)[0]
          .description.name,
        companyId: company,
        quantity: quantity,
        comment: comment,
        deadline: deadline,
      })
      .then((res) => {
        onClose();
        showToast({
          status: "success",
          title: "Order added successfully",
          description: "Your Order have been added successfully",
        });
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Please try again later",
        });
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Place New Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <FormControl>
            <FormLabel>Choose product </FormLabel>
            <Select
              placeholder="Select product"
              onChange={(e) => setProductId(e.target.value)}
            >
              {products.map((item, idx) => {
                return (
                  <option key={idx} value={item.productId}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Quantity </FormLabel>
            <Input
              ref={initialRef}
              placeholder="quantity"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Deadline </FormLabel>
            <Input
              placeholder="deadline"
              type="date"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Anything specific you want us to know? </FormLabel>
            <textarea
              placeholder="Description..."
              style={{
                width: "100%",
                border: "solid",
                borderRadius: "5px",
                borderWidth: "0.5px",
                borderColor: "",
                backgroundColor: "transparent",
                padding: "10px",
              }}
              size="lg"
              type="text"
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" mr={3} ml={3} onClick={() => placeOrder()}>
            Order
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
