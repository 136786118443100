// Chakra imports
import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import NewBlogModal from "components/modals/newBlogModal";
import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";

import axios from "axios";
import Pagination from "components/Tables/Pagination";
import LoadingIcon from "components/Icons/LoadingIcon";
import BlogsTableRow from "components/Tables/BlogsTable";

function Blogs() {
  const textColor = useColorModeValue("gsray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  //   const {
  //     isOpen: isOpenBatch,
  //     onOpen: onOpenBatch,
  //     onClose: onCloseBatch,
  //   } = useDisclosure();
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [pics, setPics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const limit = 5;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchBlogs();
  };

  const fetchBlogs = async function () {
    setIsLoading(true);
    let data = await axios
      .get(`/blogpost`)
      .then((response) => {
        setTotalBlogs(response.data.length);
        setBlogs(response.data.slice((page - 1) * limit, page * limit));
        return response.data;
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });

    if (data) {
      let temp = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].image) {
          await axios.get(`/s3?key=troofmedia/${data[i].image}`).then((res) => {
            temp.push(res.data);
          });
        }
      }
      setPics(temp);
    }
  };

  const removeBlog = (id) => {
    let temp = blogs.filter((blog) => blog.postId !== id);
    setBlogs(temp);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX="scroll" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Blog Posts
          </Text>
        </CardHeader>
        <CardBody style={{ padding: "5px" }}>
          <Button onClick={() => fetchBlogs()} style={{ margin: "5px" }}>
            <FiRefreshCw />
          </Button>
          <Button onClick={onOpen} style={{ margin: "5px" }}>
            Add Blog
          </Button>
          <NewBlogModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            done={fetchBlogs}
          />
        </CardBody>
        <CardBody style={{ marginTop: "20px" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" borderColor={borderColor} color="gray.400">
                  Blog
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Link
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Author
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Date
                </Th>
                <Th borderColor={borderColor}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                blogs.length > 0 &&
                blogs.map((row, index, arr) => {
                  return (
                    <BlogsTableRow
                      id={row.postId}
                      title={row.title}
                      link={row.link}
                      author={row.author}
                      logo={
                        pics[index]
                          ? `data:image/jpeg;base64,${pics[index]}`
                          : null
                      }
                      date={row.date}
                      isLast={index === arr.length - 1 ? true : false}
                      key={index}
                      removeBlog={removeBlog}
                    />
                  );
                })}
            </Tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                padding: "40px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                gap: "20px",
                display: "flex",
              }}
            >
              <LoadingIcon />
              <Text>Fetching blogs...</Text>
            </div>
          )}
          {blogs.length == 0 && !isLoading && (
            <div
              style={{
                padding: "15px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{ padding: "10px" }}>
                Looks like you have no blogs yet..
              </Text>
              <Button onClick={onOpen}>Add your first blog now!</Button>
            </div>
          )}
          {blogs.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalBlogs / limit + 1}
              onPageChange={handlePageChange}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Blogs;
