import {
  Table,
  Tbody,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Tr,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  Box,
  Thead,
  Td,
  Th,
} from "@chakra-ui/react";
import React from "react";

export default function ScansModal(props) {
  const { isOpen, onClose, scan } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scans Detail</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} overflowX={{ sm: "scroll" }}>
          <Table
            variant="simple"
            color={textColor}
            style={{ marginTop: "10px" }}
          >
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th color="gray.500">Country</Th>
                <Th color="gray.500">City</Th>
                <Th color="gray.500">Timestamp</Th>
                <Th color="gray.500">IP</Th>
              </Tr>
            </Thead>
            <Tbody>
              {scan &&
                scan?.ipAddress.length > 0 &&
                scan?.ipAddress.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{scan.location[index].country}</Td>
                      <Td>{scan.location[index].city}</Td>
                      <Td>
                        {scan.date ? scan.date[index] : scan.createdAt[index]}
                      </Td>
                      <Td>{scan.ipAddress[index]}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
