// Chakra imports
import {
  Flex,
  Select,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./map.css";
import useAuth from "views/Pages/useAuth";
import AnomaliesTable from "components/Tables/AnomaliesTable";
import AnomalyModal from "components/modals/anomalyModal";
import Pagination from "components/Tables/Pagination";
import LoadingIcon from "components/Icons/LoadingIcon";
import { parseMultilingualText } from "utils";

function Anomalies() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [scans, setScans] = useState([]);
  const [filteredScans, setFilteredScans] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { company } = useAuth();
  const [selectedAnomaly, setSelectedAnomaly] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timeRange, setTimeRange] = useState("Today");
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPages = 5;

  const toast = useToast();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchScans();
  };

  const selectAnomaly = (anomaly) => {
    setSelectedAnomaly(anomaly);
    onOpen();
  };

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const fetchScans = async () => {
    setIsLoading(true);
    axios
      .get(`/scan?anomalies=true&companyId=${company}`)
      .then((res) => {



        const sorted=res.data.sort((a, b) => {
          // Get the latest timestamp from the createdAt arrays
          const latestA = Math.max(...a.createdAt.map(ts => new Date(ts).getTime()));
          const latestB = Math.max(...b.createdAt.map(ts => new Date(ts).getTime()));
          
          // Compare the latest timestamps
          return latestB - latestA; // Sort from newest to oldest
      });

        setScans(sorted);
        setFilteredScans(sorted);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (company) {
      fetchScans();
    }
  }, [company]);

  useEffect(() => {
    setIsLoading(true)
    let filteredData = [];
    if (timeRange === "currentWeek") {
      const currentDate = new Date();
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      ).setHours(0, 0, 0, 0);
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay())
      ).setHours(0, 0, 0, 0);
  
      filteredData = scans.filter((item) => {
        const itemDate = new Date(item.createdAt[item.createdAt.length-1]).setHours(0, 0, 0, 0);
        return itemDate >= startOfWeek && itemDate <= endOfWeek;
      });
    }
  
    if (timeRange === "currentMonth") {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
  
      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Get the number of days in the current month
  
      filteredData = scans.filter((item) => {
        const itemYear = new Date(item.createdAt[item.createdAt.length-1]).getFullYear();
        const itemMonth = new Date(item.createdAt[item.createdAt.length-1]).getMonth();
        return itemYear === currentYear && itemMonth === currentMonth;
      });
    }

    if (timeRange === "today") {
      const currentDate = new Date();
      const today = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ).setHours(0, 0, 0, 0);
  
      filteredData = scans.filter((item) => {
        const itemDate = new Date(item.createdAt[item.createdAt.length-1]).setHours(0, 0, 0, 0);
        return itemDate === today;
      });
    }
    if (timeRange === "yesterday") {
      const currentDate = new Date();
  
      const yesterday = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 1
      ).setHours(0, 0, 0, 0);
  
      filteredData = scans.filter((item) => {
        const itemDate = new Date(item.createdAt[item.createdAt.length-1]).setHours(0, 0, 0, 0);
        return itemDate === yesterday;
      });
    }

    if (timeRange === "currentYear") {
      const currentYear = new Date().getFullYear();
  
      filteredData = scans.filter((item) => {
        const itemYear = new Date(item.createdAt[item.createdAt.length-1]).getFullYear();
        return itemYear === currentYear;
      });
    }

if (timeRange =="default"){
  setFilteredScans(scans)
}else{
      setFilteredScans(filteredData)
}
      setIsLoading(false)

  }, [timeRange]);

  return (
    <Flex direction="column" gap={8} pt={{ base: "120px", md: "75px" }}>
      <Card overflowX="scroll" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Suspicious scans
          </Text>
        </CardHeader>
        <CardBody>
        <Flex direction="column" p="0px 0px 28px 22px" width={'35%'}>
                  <Select
                    variant="flushed"
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                    
                  >
                   <option value="default">Time</option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="currentWeek">Current Week</option>
                    <option value="currentMonth">Current Month</option>
                    <option value="currentYear">Current Year</option>
                  </Select>
                </Flex>
          <Table
            variant="simple"
            color={textColor}
            style={{ marginTop: "10px" }}
          >
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th borderColor={borderColor} color="gray.500">
                  Product
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Tag
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Status
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Distributor
                </Th>
                <Th borderColor={borderColor} color="gray.500"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading && filteredScans.length>0 &&
                filteredScans.map((row, index, arr) => {
                  return (
                    <AnomaliesTable
                      logo={""}
                      id={parseMultilingualText(row.productName)}
                      country={row.location}
                      city={row.location}
                      status={"Scanned different city/country"}
                      key={index}
                      isLast={index === arr.length - 1}
                      date={row.createdAt}
                      tag={row.tagId}
                      ip={row.ipAddress}
                      flag={row.flag}
                      distributor={
                        row.distributorId == undefined
                          ? "unknown"
                          : row.distributorId
                      }
                      selectAnomaly={selectAnomaly}
                      company={row.companyId}
                    />
                  );
                })}
  

  {!isLoading && filteredScans.length === 0 && (
  <div
    style={{
      padding: "15px",
      marginLeft: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%", // Ensures it takes up full height
      width: "100%",  // Ensures it takes up full width
    }}
  >
    <Text style={{ padding: "10px" }}>No anomaly</Text>
  </div>
)}

            </Tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                padding: "40px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                gap: "20px",
                display: "flex",
              }}
            >
              <LoadingIcon />
              <Text>Fetching scans...</Text>
            </div>
          )}
         {/*  <Pagination
            currentPage={currentPage}
            totalPages={scans.length / 5 + 1}
            onPageChange={handlePageChange}
          /> */}
        </CardBody>
      </Card>
      <AnomalyModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        anomaly={selectedAnomaly}
      />
    </Flex>
  );
}

export default Anomalies;
