import {
  Accordion,
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { EyeIcon } from "components/Icons/Icons";
import { EmailIcon } from "@chakra-ui/icons";
import React, { useState, useEffect } from "react";
import axios from "axios";

function AnomaliesTable(props) {
  const {
    logo,
    name,
    id,
    country,
    city,
    status,
    date,
    tag,
    distributor,
    isLast,
    selectAnomaly,
    company,
    flag,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [dist, setDist] = useState({ email: "contact@marjane.com" });

  const toast = useToast();
  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };
  const fetchDistributor = async () => {
    axios
      .get(`/distributor?distributorId=${distributor}&companyId=${company}`)
      .then((res) => {
        if (res.data) {
          setDist(res.data);
        }
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong",
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (distributor) {
      fetchDistributor();
    }
  }, [distributor]);
  return (
    <>
      <Tr>
        <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {id}
            </Text>
          </Flex>
        </Td>
        <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {tag}
            </Text>
          </Flex>
        </Td>

        <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
          <Badge
            bg={flag == 2 ? "gray" : "yellow"}
            color={flag == 2 ? "white" : "black"}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {flag == 1 ? "Suspicious" : "Gray Market"}
          </Badge>
        </Td>

        <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {distributor}
          </Text>
        </Td>
        <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
          <Button
            style={{ margin: "4px" }}
            onClick={() => selectAnomaly({ city, country, date })}
          >
            <EyeIcon color={textColor} w="22px" h="22px" />
          </Button>
          <Button style={{ margin: "4px" }}>
            <a
              href={`mailto:${dist.email}?subject=${encodeURIComponent(
                `suspicious tag N°: ${tag}`
              )}`}
            >
              <EmailIcon color={textColor} w="22px" h="22px" />
            </a>
          </Button>
        </Td>
      </Tr>
    </>
  );
}

export default AnomaliesTable;
