// Chakra imports
import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import OrderModal from "components/modals/orderModal";
import TablesOrdersRow from "components/Tables/TablesOrdersRow";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "views/Pages/useAuth";
import { FiRefreshCw } from "react-icons/fi";
import Pagination from "components/Tables/Pagination";
import LoadingIcon from "components/Icons/LoadingIcon";

function Orders() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const { company } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPages = 5;

  const toast = useToast();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchOrders();
  };

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const fetchOrders = async function () {
    setIsLoading(true);
    await axios
      .get(`/order?companyid=${company}`)
      .then((response) => {
        setOrders(response.data);
        return response.data;
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the orders",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, [company]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card my="22px" overflowX="scroll" pb="22px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
              Orders
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Button onClick={onOpen}>New Order</Button>
          <OrderModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </CardBody>
        <CardBody style={{ marginTop: "20px" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px">
                <Th
                  pl="0px"
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Order N°
                </Th>
                <Th
                  pl="0px"
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Order ID
                </Th>
                <Th
                  pl="0px"
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Product
                </Th>
                <Th
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Quantity
                </Th>
                <Th
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Status
                </Th>
                <Th
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Placed on
                </Th>
                <Th
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Due
                </Th>
                <Th
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                >
                  Remaining
                </Th>
                <Th
                  color="gray.400"
                  borderColor={borderColor}
                  whiteSpace={"nowrap"}
                ></Th>
              </Tr>
            </Thead>

            <Tbody>
              {!isLoading &&
                orders.map((row, index, arr) => {
                  return (
                    <TablesOrdersRow
                      product={row.productName}
                      orderID={row.orderId}
                      status={0}
                      quantity={row.quantity}
                      progression={parseInt(
                        (new Date(row.deadline).getTime() -
                          new Date(row.createdAt).getTime()) /
                          (24 * 60 * 60 * 1000)
                      )}
                      due={row.deadline}
                      submission={row.createdAt}
                      isLast={index === arr.length - 1}
                      key={index}
                      orderNumber={"0" + index}
                    />
                  );
                })}
            </Tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                padding: "40px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                gap: "20px",
                display: "flex",
              }}
            >
              <LoadingIcon />
              <Text>Fetching orders...</Text>
            </div>
          )}
          {!isLoading && orders.length == 0 && (
            <div
              style={{
                padding: "15px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{ padding: "10px" }}>
                Looks like you have placed no orders yet..
              </Text>
              <Button onClick={onOpen}>Create your first order now!</Button>
            </div>
          )}
          {orders.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Orders;
