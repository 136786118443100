// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    Link,
    Switch,
    Text,
    useColorModeValue,
    LightMode,
    useToast,
  } from "@chakra-ui/react";
  import axios from "axios";
  import { useLocation } from 'react-router-dom'; 
  // Assets
  import BgSignUp from "assets/img/BgSignUp.png";
  import React, { useContext, useEffect, useMemo, useState } from "react";
  import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
  import logoTroof from "assets/img/troof_logo.png";
  import { useHistory } from "react-router-dom";

  function ResetPassword() {
    const bgForm = useColorModeValue("white", "navy.800");
    const titleColor = useColorModeValue("gray.700", "blue.500");
    const textColor = useColorModeValue("gray.700", "white");
    const [email, setEmail] = useState();
    const [role, setRole] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [tempPassword, setTempPassword] = useState();
    const handleInputChange = (e) => setPassword(e.target.value);
    const history = useHistory();

  
    const toast = useToast();
  
    const showToast = ({ status, title, description }) => {
      toast({
        title: title,
        description: description,
        status: status,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    };
  
      const handleKeyDown = (e) => {
  
 /*  if (e.keyCode==13){
    signInUser()
  } */
      
  
      }


      const location = useLocation(); // Get the current location object

      useEffect(() => {
        // Create a URLSearchParams object to parse query parameters
        const queryParams = new URLSearchParams(location.search);
    
        // Extract the email and role from the query parameters
        const emailFromQuery = queryParams.get('email') || '';
        const roleFromQuery = queryParams.get('role') || '';
        const tokenFromQuery = queryParams.get('token') || '';
        // Set the state with the extracted values
        setEmail(emailFromQuery);
        setRole(roleFromQuery);
        setTempPassword(tokenFromQuery)
      }, [location.search]);
  
    const ActivateAccount = () => {
      let passwordValid= password == confirmPassword
if (!passwordValid){
  showToast({
    status: "error",
    title: "Invalid Password",
    description: "Passwords don't match",
});
}
      if (password && tempPassword && passwordValid) {
     axios
          .put("/users?activate=true", { tempPassword: tempPassword , password: password, email: email, role: role })
          .then(function (response) {
  
     if (response.status==200){
 history.push("/auth/signin");
     }
          })
          .catch(function (error) {
            showToast({
              status: "error",
              title: "Account Not Found",
              description: "We couldn't validate account, please try again with correct credentials",
            });
          });
  

      } 
    };
  
    return (
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Box
          position="absolute"
          minH={{ base: "70vh", md: "50vh" }}
          maxH={{ base: "70vh", md: "50vh" }}
          w={{ md: "calc(100vw - 50px)" }}
          maxW={{ md: "calc(100vw - 50px)" }}
          left="0"
          right="0"
          bgRepeat="no-repeat"
          overflow="hidden"
          zIndex="-1"
          top="0"
          //bgImage={BgSignUp}
          bgSize="cover"
          mx={{ md: "auto" }}
          mt={{ md: "14px" }}
          borderRadius={{ base: "0px", md: "20px" }}
        >
          <Box w="100vw" h="100vh" bg="blue.500" opacity="0.8"></Box>
        </Box>
        <Flex
          direction="column"
          textAlign="center"
          justifyContent="center"
          align="center"
          mt="50px"
          mb="30px"
        >
          <img src={logoTroof} width="80px" height="80px" />
          <Text fontSize="4xl" color="white" fontWeight="bold">
          Reset your password
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}
          >
            <HStack spacing="15px" justify="center" mb="22px"></HStack>
  
            <FormControl isRequired         onKeyDown={(e)=>handleKeyDown(e)}>
             
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              New Password
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms="4px"
                type="password"
                placeholder="Your password"
                mb="24px"
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Confirm New Password
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms="4px"
                type="password"
                placeholder="Your password"
                mb="24px"
                size="lg"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            
              <Button
                fontSize="10px"
                variant="dark"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                type="submit"
                onClick={ActivateAccount}
  
              >
                Reset Password
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
  
  export default ResetPassword;
  