import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Icon,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";

import { FaTrashAlt } from "react-icons/fa";
import React from "react";
import {  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody} from '@chakra-ui/react';
import axios from "axios";

function AccountsTableRow(props) {
  const { name, email, company, status, isLast, role, tempPass } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const handleConfirm = async () => {

    let data = JSON.stringify({
      email: email,
    role: role
    });
    
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    await axios.delete("/users",config )
    .then((response) => {

if (response.status==200){
  showToast({
    status: "info",
    title: "Deleted User Successfully",
  });

}
})
    .catch((e) => {

      showToast({
        status: "error",
        title: "Server error",
        description: "Something went wrong while deleting  the account",
      });
    });
    onClose()
  };
  return (
    <>
    <Tr className="row">
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {email}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {company}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Badge
          bg={status === "inactive" ? "orange.400" : "green.400"}
          color={status === "ok" ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {role}
          </Text>
        </Flex>
      </Td>
{/*       {status == "inactive" ? (
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Badge
            bg={status === "inactive" ? "orange.400" : "green.400"}
            color={status === "ok" ? "white" : "white"}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {tempPass}
          </Badge>
        </Td>
      ) : (
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        ></Td>
      )} */}

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex
          direction={{ sm: "column", md: "row" }}
          align="flex-start"
          p={{ md: "24px" }}
        >
       {/*    <Button p="0px" bg="transparent" variant="no-effects">
            <Flex color={textColor} cursor="pointer" align="center" p="12px">
              <Icon as={FiRefreshCcw} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                RESET PASSWORD
              </Text>
            </Flex>
          </Button> */}

          <Button
            p="0px"
            bg="transparent"
            variant="no-effects"
            mb={{ sm: "10px", md: "0px" }}
            me={{ md: "12px" }}
          >
            <Flex color="red.500" cursor="pointer" align="center" p="12px">
             
              <Button onClick={onOpen}>
              <Icon as={FaTrashAlt} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                DELETE
              </Text>
              </Button>
            </Flex>
          </Button>
        </Flex>
      </Td>
    </Tr>


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalBody>
            Are you sure you want to proceed with this operation?
          </ModalBody>
          <ModalFooter >
            
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleConfirm} m="5">
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AccountsTableRow;
