import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { DownloadIcon } from "@chakra-ui/icons";

export default function NewBlogModal(props) {
  const { isOpen, onClose, done } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState();
  const [link, setLink] = useState();
  const [author, setAuthor] = useState();
  const [date, setDate] = useState();
  const { colorMode } = useColorMode();

  const toast = useToast();

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: "auto",
    height: 200,
    padding: 4,
    boxSizing: "border-box",
    diplay: "flex",
    borderRadius: "15px",
    borderColor: "gray",
    border: "dotted",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
    padding: "5px",
    borderRadius: "15px",
  };

  const imageContainer = {
    padding: "10px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "space-between",
    borderRadius: "15px",
    borderColor: "gray",
  };
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
    },
  });

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const onSubmit = function () {
    try {
      const pictureName = `blog_${title}_${Math.random(10)}`;
      axios
        .put(`/s3?key=troofmedia/${pictureName}`, files[0], {
          headers: {
            "Content-Type": files[0].type,
          },
        })
        .then((res) => {})
        .catch((e) => {});

      axios
        .post("/blogpost", {
          title: title,
          link: link,
          author: author,
          date: new Date(date).toDateString(),
          image: pictureName,
        })
        .then((res) => {
          onClose();
          showToast({
            status: "success",
            title: "Blog added successfully",
            description: "Your Blog have been added successfully",
          });
          done();
        })
        .catch((e) => {
          showToast({
            status: "error",
            title: "Server error",
            description: "Please try again later",
          });
        });
    } catch (e) {
      showToast({
        status: "error",
        title: "Server error",
        description: "Something went wrong",
      });
    }
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));
  const filepath = files.map((file) => <li key={file.path}>{file.path}</li>);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Add New Blog</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <FormControl isRequired>
            <FormLabel>Blog Title </FormLabel>
            <Input
              placeholder="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Blog Link </FormLabel>
            <Input
              placeholder="link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Blog Author </FormLabel>
            <Input
              placeholder="author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Blog Date </FormLabel>
            <Input
              placeholder="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
            />
          </FormControl>

          <div
            {...getRootProps()}
            style={{
              borderRadius: "10px",
              padding: "20px",
              borderColor: "gray",
              alignItems: "center",
              justifyItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "transparent",
              border: "dotted",
              marginTop: "15px",
              color: colorMode,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the picture here ...</p>
            ) : (
              <>
                <DownloadIcon w={6} h={6} marginRight={"4px"} />
                <p>
                  Drag 'n' drop your blog picture here, or click to select
                  picture
                </p>
              </>
            )}
          </div>
          {thumbs.length > 0 && (
            <div>
              <aside>
                <h3 style={{ fontSize: "20px", margin: "10px" }}>
                  Blog Picture
                </h3>
              </aside>

              <div style={imageContainer}>{thumbs}</div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            colorScheme="blue"
            mr={3}
            ml={3}
            onClick={() => onSubmit()}
            type="submit"
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
