// import
import React, { Component } from "react";
import Dashboard from "views/Dashboard/Dashboard.js";
import Products from "views/Dashboard/Products.js";
import Blogs from "views/Dashboard/Blogs.js";

import Profile from "views/Dashboard/Profile.js";

import SignUp from "views/Pages/SignUp.js";
import Scans from "views/Dashboard/Scans";

import { PersonIcon, DocumentIcon } from "components/Icons/Icons";
import Orders from "views/Dashboard/Orders";
import { IoBag } from "react-icons/io5";
import { AiOutlineDashboard } from "react-icons/ai";
import { FaBug, FaStore, FaTruckLoading, FaUsers } from "react-icons/fa";
import { RiQrScanLine } from "react-icons/ri";
import Companies from "views/Dashboard/Companies";
import Users from "views/Dashboard/Users";
import Distributors from "views/Dashboard/Distributors";
import Anomalies from "views/Dashboard/Anomalies";
import { LockIcon } from "@chakra-ui/icons";
import ActivateAccount from "views/Pages/ActivateAccount";
import ForgotPassword from "views/Pages/ForgotPassword";
import ResetPassword from "views/Pages/ResetPassword";


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <AiOutlineDashboard color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Products",
    rtlName: "لوحة القيادة",
    icon: <IoBag color="inherit" />,
    component: Products,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "لوحة القيادة",
    icon: <FaTruckLoading color="inherit" />,
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/scans",
    name: "Scans",
    rtlName: "لوحة القيادة",
    icon: <RiQrScanLine color="inherit" />,
    component: Scans,
    layout: "/admin",
  },

  /*   {
    path: "/companies",
    name: "Companies",
    rtlName: "",
    icon: <FaStore color="inherit" />,
    component: Companies,
    layout: "/admin",
  }, */
  {
    path: "/distributors",
    name: "Distributors",
    rtlName: "",
    icon: <FaStore color="inherit" />,
    component: Distributors,
    layout: "/admin",
  },
  {
    path: "/anomalies",
    name: "Anomalies",
    rtlName: "لوحة القيادة",
    icon: <FaBug color="inherit" />,
    component: Anomalies,
    layout: "/admin",
  },
  {
    path: "/blogs",
    name: "Blogs",
    rtlName: "",
    icon: <IoBag color="inherit" />,
    component: Blogs,
    layout: "/admin",
  },
    {
    path: "/users",
    name: "Users",
    rtlName: "",
    icon: <FaUsers color="inherit" />,
    component: Users,
    layout: "/admin",
  }, 
  {
    name: "My Account",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/signin",
        name: "Log out",
        rtlName: "لوحة القيادة",
        icon: <LockIcon color="inherit" />,
        component: SignUp,
        layout: "/auth",
      },
      {
        path: "/account/activate",
        name: "Activate Account",
        rtlName: "لوحة القيادة",
        icon: <LockIcon color="inherit" />,
        component: ActivateAccount,
        layout: "/auth",
      },
      {
        path: "/account/forgot-password",
        name: "Forgot Password",
        rtlName: "لوحة القيادة",
        icon: <LockIcon color="inherit" />,
        component: ForgotPassword,
        layout: "/auth",
      },
      {
        path: "/account/reset-password",
        name: "Reset Password",
        rtlName: "لوحة القيادة",
        icon: <LockIcon color="inherit" />,
        component: ResetPassword,
        layout: "/auth",
      },
      
    ],
  },
];
export default dashRoutes;
