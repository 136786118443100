import {
  Avatar,
  Badge,
  Button,
  Flex,
  Icon,
  Spinner,
  Td,
  Text,
  Tr,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { EyeIcon } from "components/Icons/Icons";
import ConfirmModal from "components/modals/confirmModal";
import EditProductModal from "components/modals/editProductModal";
import React, { useEffect } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import useAuth from "views/Pages/useAuth";

function ProductsTableRow(props) {
  const {
    logo,
    name,
    description,
    id,
    brand,
    date,
    isLast,
    category,
    productObject,
    removeProduct,
    refetch,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const DeleteDisclosure = useDisclosure();
  const EditDisclosure = useDisclosure();
  const { colorMode } = useColorMode();
  const { company } = useAuth();
  const [logoImage, setLogoImage] = React.useState("LOADING");
  const [productName, setProductName] = React.useState(name);
  const [productDescription, setProductDescription] = React.useState(
    description
  );
  const [productCategory, setProductCategory] = React.useState(category);

  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const updateProduct = (productData) => {
    EditDisclosure.onClose();
    if (productData) {
      setProductName(productData.name);
      setProductDescription(productData.description);
      setProductCategory(productData.category);
    }
    refetch();
  };

  const deleteProduct = async () => {
    await axios
      .delete(`/product?productId=${id}&companyId=${company}`)
      .then(() => {
        removeProduct(id);
        showToast({
          status: "success",
          title: "Product deleted",
          description: "Product deleted successfully",
        });
      })
      .catch((error) => {
        showToast({
          status: "error",
          title: "Error",
          description: "Error deleting Product",
        });
      });
    DeleteDisclosure.onClose();
  };

  useEffect(() => {
    async function fetchLogoImage() {
      if (logo !== null) {
        try {
          const res = await axios.get(`/s3?key=troofmedia/${logo}`);
          setLogoImage(`data:image/jpeg;base64,${res.data}`);
        } catch (error) {
          console.error("Error fetching logo image:", error);
        }
      } else {
        setLogoImage(null);
      }
    }

    fetchLogoImage();
  }, [logo]);

  return (
    <Tr className="row">
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {logoImage == "LOADING" ? (
            <Spinner mr="15px" color="grey"></Spinner>
          ) : (
            <Avatar
              src={logoImage}
              w="80px"
              h="80px"
              borderRadius="12px"
              me="18px"
              ml="10px"
            />
          )}
          <Flex direction="column">
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {productName}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {productDescription}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {id}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {productCategory}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {date}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Button
          p="0px"
          bg="transparent"
          variant="no-effects"
          mb={{ sm: "10px", md: "0px" }}
          me={{ md: "12px" }}
        >
          <Flex
            color="red.500"
            cursor="pointer"
            align="center"
            p="12px"
            onClick={DeleteDisclosure.onOpen}
          >
            <Icon as={FaTrashAlt} me="4px" />
          </Flex>
        </Button>
        <Button p="0px" bg="transparent" variant="no-effects">
          <Flex
            color={textColor}
            cursor="pointer"
            align="center"
            p="12px"
            onClick={EditDisclosure.onOpen}
          >
            <Icon as={FaPencilAlt} me="4px" />
          </Flex>
        </Button>
        <a
          href={`https://troof.io/${id}?companyId=${company}&preview=true`}
          target="_blank"
        >
          <Button p="0px" bg="transparent" variant="no-effects">
            <Flex color={textColor} cursor="pointer" align="center" p="12px">
              <EyeIcon color={colorMode} w="22px" h="22px" me="4px" />
            </Flex>
          </Button>
        </a>

        <ConfirmModal
          isOpen={DeleteDisclosure.isOpen}
          onOpen={DeleteDisclosure.onOpen}
          onClose={DeleteDisclosure.onClose}
          confirmDelete={deleteProduct}
        />

        <EditProductModal
          isOpen={EditDisclosure.isOpen}
          onOpen={EditDisclosure.onOpen}
          onClose={(res) => updateProduct(res)}
          productObject={productObject}
        />
      </Td>
    </Tr>
  );
}

export default ProductsTableRow;
