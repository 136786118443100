import { Button } from "@chakra-ui/react";
import { MdLocationOn, MdOutlineClose } from "react-icons/md";

export default function MapMarker({
  text,
  totalScans,
  city,
  handleClickInfo,
  showLocationInfo,
  indexT,
  openScansModal,
  suspicious,
}) {
  const K_WIDTH = 40;
  const K_HEIGHT = 40;
  const greatPlaceStyle = {
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    display: "grid",
    fontSize: 10,
    fontWeight: "bold",
    padding: 4,
  };

  return (
    <div style={greatPlaceStyle} onClick={handleClickInfo}>
      <MdLocationOn
        style={{
          position: "absolute",
          left: "5px",
          top: "-10px",
          zIndex: 10,
        }}
        size={30}
        color={suspicious}
      />
      {indexT == showLocationInfo ? (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            fontSize: "16px",
            position: "fixed",
            color: "black",
            textAlign: "left",
            fontWeight: "normal",
            width: "200px",
            zIndex: 100,
          }}
        >
          <MdOutlineClose
            size={15}
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              cursor: "pointer",
            }}
          />

          <p style={{ fontWeight: "bold", marginBottom: "5px" }}>{city}</p>
{/*           <p style={{ fontSize: "14px" }}>product: {text}</p> */}
          <p
            style={{
              marginTop: "4px",
              fontSize: "14px",
            }}
          >
            Total scans: {totalScans}
          </p>
          <Button
            variant="link"
            style={{
              marginTop: "10px",
            }}
            colorScheme="blue"
            fontSize={"12px"}
            onClick={openScansModal}
          >
            More details
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
